import { PropsWithChildren } from "react";
import { Box, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import TagManager from "react-gtm-module";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { Parameters } from "../../utils/types/parameters";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const white = COLORS.BLACK_WHITE.WHITE;

const cardSx = {
  width: "30%",
  minWidth: "300px",
  height: "190px",

  padding: "20px",
  borderRadius: "16px",
  border: `1px solid ${grey200}`,
  ":hover": { backgroundColor: grey100, cursor: "pointer" },
};

const backgroundIconSx = {
  height: "46px",
  width: "46px",
  borderRadius: "16px",
  backgroundImage:
    "linear-gradient(105deg, #05E4C5 15%, #006CFA 30%, #711CFF 50%, #FF007D 70%, #F72717 85%)",
};

const iconSx = {
  height: "40px",
  width: "40px",
  borderRadius: "13px",
  backgroundColor: white,
};

const titleSx = {
  color: greyDark,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",

  maxHeight: "25px",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const questionSx = {
  maxHeight: "40px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
};

export interface AssistantTemplateProps extends PropsWithChildren {
  clickAssistant: (
    title: string,
    question: string,
    parameters: Parameters
  ) => void;
  parameters: Parameters;
  question: string;
  title: string;
}

export function AssistantTemplate({
  clickAssistant,
  parameters,
  question,
  title,
}: AssistantTemplateProps): JSX.Element {
  const { accounts } = useMsal();
  const userId = accounts[0]?.homeAccountId || "";
  const userLanguage = navigator.language || "en";
  const userLocation = "Unknown"; // Replace with actual location if available

  function handleClickCard(): void {
    // Push the 'assistant' event to dataLayer
    const dataLayerArgs = {
      dataLayer: {
        event: "assistant",
        website: "EdenChat", // Replace with your website name if different
        env_work: process.env.NODE_ENV || "development",
        assistant_name: title,
        user_data: {
          user_id: userId,
          user_location: userLocation,
          user_language: userLanguage,
        },
      },
    };
    TagManager.dataLayer(dataLayerArgs);

    // Proceed with the existing click handler
    clickAssistant(title, question, parameters);
  }

  return (
    <Box className="flex flex-col gap-4" onClick={handleClickCard} sx={cardSx}>
      <div className="flex justify-start">
        <Box className="flex items-center justify-center" sx={backgroundIconSx}>
          <Box className="flex items-center justify-center" sx={iconSx}>
            <CustomIcon filename="code-2-base-grey-dark.svg" />
          </Box>
        </Box>
      </div>

      <div>
        <Typography sx={titleSx}>{title}</Typography>
        <Typography sx={questionSx}>{question}</Typography>
      </div>
    </Box>
  );
}
