import { axiosClient } from ".";
import { Message } from "../utils/types/message";

export async function getConversation(
  conversationId: string
): Promise<Message[]> {
  const _params = {
    conversation_id: conversationId,
    number_of_messages: 100,
    last_message_index: 100,
  };

  return new Promise((resolve) => {
    axiosClient
      .post(
        "/Edenchat/GetConversationById",
        {},
        {
          params: _params,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        const messagesResponse: dataMessageResponse[] = response.data;
        resolve(formatDataToMessage(messagesResponse));
      })
      .catch((error) => console.error(error));
  });
}

interface dataMessageResponse {
  readonly question: string;
  readonly answer: string;
  readonly date: string;
}

function formatDataToMessage(data: dataMessageResponse[]): Message[] {
  const messageList: Message[] = [];
  data.forEach((dataItem: dataMessageResponse) => {
    const newQuestion: Message = {
      content: dataItem.question,
      type: "question",
    };
    const newAnswer: Message = { content: dataItem.answer, type: "answer" };
    messageList.push(newQuestion);
    messageList.push(newAnswer);
  });
  return messageList;
}
