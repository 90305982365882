// src/authService.ts

import msalInstance from "./msalInstance";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const getAccessToken = async (scopes: string[]): Promise<string> => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    throw new Error("No user accounts found.");
  }

  const account = accounts[0];

  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: account,
    });
    return response.accessToken;
  } catch (error: any) {
    if (error instanceof InteractionRequiredAuthError) {
      // Interaction required, prompting user to sign in
      try {
        const response = await msalInstance.acquireTokenPopup({
          scopes: scopes,
          account: account,
        });
        return response.accessToken;
      } catch (popupError) {
        console.error("Interactive token acquisition failed:", popupError);
        throw popupError;
      }
    } else {
      console.error("Error acquiring access token:", error);
      throw error;
    }
  }
};
