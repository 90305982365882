// index.tsx
import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import TagManager from "react-gtm-module";

import "./index.scss";
import App from "./App";
import { theme } from "./utils/styles/theme";
import msalInstance from "./auth/msalInstance";

// Retrieve the GTM ID from environment variables
const GTM_ID = process.env.REACT_APP_GTM_ID ?? "";

if (GTM_ID) {
  // Initialize GTM with the ID from environment variables
  const tagManagerArgs = {
    gtmId: GTM_ID,
  };
  TagManager.initialize(tagManagerArgs);
} else {
  console.warn(
    "GTM ID is not set. Please define REACT_APP_GTM_ID in your .env file."
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </MsalProvider>
);
