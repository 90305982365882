import { Box } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

import {
  ConversationHistory,
  ParametersFromBFF,
} from "../utils/types/conversationHistory";
import { defaultParameters, Parameters } from "../utils/types/parameters";
import { Footer, Navigation } from "./layouts";
import { formatConversationParameters } from "../utils/formatConversationParameters";
import { getConversation, getHistory } from "../api";
import { HistoryDrawer } from "./history";
import { MainContent } from "./MainContent";
import { Message } from "../utils/types/message";
import { ModelsType } from "../utils/types/models";
import { SettingsDialog } from "./settings/SettingsDialog";

const TRANSITION = "transition-all ease-in-out duration-500";

const NAVBAR_WIDTH = 80;

const HISTORY_DRAWER_WIDTH = 306;
const FOOTER_HEIGHT = 72;

function getQueryParamValue(paramName: string): string | null {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

export interface PageLayoutProps extends PropsWithChildren {
  readonly navbarWidth: number;
}

export function PageLayout({ navbarWidth }: PageLayoutProps): JSX.Element {
  useMsal();

  const [history, setHistory] = useState<ConversationHistory[]>([]);

  const [messages, setMessages] = useState<Message[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [convId, setConvId] = useState<string | null>(null);
  const [convExited, setConvExited] = useState<boolean>(false);

  const [displayLoader, setDisplayLoader] = useState<boolean>(false);

  const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);

  // used to trigger historyUpdate() in HistoryDrawer on message first message sent
  const [firstAnswer, setFirstAnswer] = useState<boolean>(false);

  const [parameters, setParameters] = useState<Parameters>(defaultParameters);

  const [libraryOpened, setLibraryOpened] = useState<boolean>(false);

  const [historyOpened, setHistoryOpened] = useState<boolean>(false);
  const openHistory = () => setHistoryOpened(true);
  const closeHistory = () => setHistoryOpened(false);

  const [modelSelected, setModelSelected] = useState<ModelsType>(
    defaultParameters.llm!
  );
  const [text, setText] = useState<string>("");

  const [normalAssistant, setNormalAssistant] = useState<boolean>(false);
  const [smartAssistant, setSmartAssistant] = useState<boolean>(false);
  const [assistantTitle, setAssistantTitle] = useState<string>("");
  const [assistantTags, setAssistantTags] = useState<string[]>([]);
  const [assistantUrl, setAssistantUrl] = useState<string>("");
  const [qParamRagId, setQParamRagId] = useState<string | null>();
  const [ragId, setRagId] = useState<string>(qParamRagId ?? "0");

  const [file, setFile] = useState<File | null>(null);

  const [settingsOpened, setSettingsOpened] = useState<boolean>(false);
  const closeSettings = () => setSettingsOpened(false);
  const openSettings = () => setSettingsOpened(true);

  useEffect(() => {
    const qParamRagId = getQueryParamValue("ragId");
    setQParamRagId(qParamRagId);
  }, []);

  function clearQueryParam(paramName: string): void {
    const url = new URL(window.location.href);
    url.searchParams.delete(paramName);
    window.history.replaceState({}, "", url.toString());
  }

  function clearAssistant() {
    setNormalAssistant(false);
    setSmartAssistant(false);
    setAssistantTitle("");
    setAssistantTags([]);
    setAssistantUrl("");
  }

  function handleNewChat(): void {
    clearQueryParam("ragId");
    setQParamRagId(null);
    setConvExited(true);
    setDisplayLoader(false);
    setLibraryOpened(false);
    setConvId(null);
    setText("");
    setParameters(defaultParameters);
    setMessages([]);
    setIsEmpty(true);
    setRagId("0");
    clearAssistant();
    setFile(null);
  }

  function handleOpenLibrary(ragId: string = "0"): void {
    if (!ragId || ragId === "0") {
      clearQueryParam("ragId");
    }
    setQParamRagId(ragId);
    setConvExited(true);
    setHistoryOpened(false);
    setDisplayLoader(false);
    setConvId(null);
    setParameters(defaultParameters);
    setMessages([]);
    setIsEmpty(true);
    setRagId(ragId);
    clearAssistant();
    setLibraryOpened(true);
    setFile(null);
  }

  async function handleClickConversation(
    _convId: string,
    _parameters: ParametersFromBFF,
    _ragId: string
  ) {
    clearQueryParam("ragId");
    const convMessages = await getConversation(_convId);
    setConvExited(true);
    setDisplayLoader(false);
    setMessages(convMessages);
    setConvId(_convId);
    const conversationParameters = formatConversationParameters(_parameters);
    setParameters(conversationParameters);
    setRagId(_ragId);
    clearAssistant();
    setLibraryOpened(false);
    setIsEmpty(false);
    setText("");
    setFile(null);
  }

  async function historyUpdate() {
    const responseHistory = await getHistory();
    setHistory(responseHistory);
  }

  return (
    <>
      <Navigation
        navbarWidth={NAVBAR_WIDTH}
        openHistory={openHistory}
        closeHistory={closeHistory}
        historyOpened={historyOpened}
      />
      <Box
        className="flex flex-col"
        sx={{ width: `calc(100% - ${navbarWidth}px)` }}
      >
        <Box
          className="flex w-full flex-grow"
          sx={{ height: `calc(100% - ${FOOTER_HEIGHT}px)` }}
        >
          <HistoryDrawer
            closeHistory={closeHistory}
            convId={convId}
            drawerWidth={HISTORY_DRAWER_WIDTH}
            firstAnswer={firstAnswer}
            handleClickConversation={handleClickConversation}
            handleNewChat={handleNewChat}
            handleOpenLibrary={handleOpenLibrary}
            history={history}
            historyOpened={historyOpened}
            historyUpdate={historyUpdate}
            libraryOpened={libraryOpened}
            setFirstAnswer={setFirstAnswer}
            transition={TRANSITION}
          />

          <MainContent
            assistantTags={assistantTags}
            assistantTitle={assistantTitle}
            assistantUrl={assistantUrl}
            convId={convId}
            convExited={convExited}
            file={file}
            handleNewChat={handleNewChat}
            historyDrawerWidth={HISTORY_DRAWER_WIDTH}
            handleOpenLibrary={handleOpenLibrary}
            historyUpdate={historyUpdate}
            historyOpened={historyOpened}
            isEmpty={isEmpty}
            displayLoader={displayLoader}
            libraryOpened={libraryOpened}
            messages={messages}
            modelSelected={modelSelected}
            normalAssistant={normalAssistant}
            openHistory={openHistory}
            openSettings={openSettings}
            parameters={parameters}
            ragId={ragId}
            setAssistantTags={setAssistantTags}
            setAssistantTitle={setAssistantTitle}
            setAssistantUrl={setAssistantUrl}
            setConvId={setConvId}
            setConvExited={setConvExited}
            setFile={setFile}
            setFirstAnswer={setFirstAnswer}
            setIsEmpty={setIsEmpty}
            setDisplayLoader={setDisplayLoader}
            setLibraryOpened={setLibraryOpened}
            setMessages={setMessages}
            setNormalAssistant={setNormalAssistant}
            setParameters={setParameters}
            setRagId={setRagId}
            setSmartAssistant={setSmartAssistant}
            setText={setText}
            setWaitingForAnswer={setWaitingForAnswer}
            smartAssistant={smartAssistant}
            text={text}
            transition={TRANSITION}
            waitingForAnswer={waitingForAnswer}
            history={history}
            initialRagId={qParamRagId}
          />

          {/* wrapped in a conditional to only render the SettingsDialog component when it's open (prevents useless useEffect trigger) */}
          {settingsOpened && (
            <SettingsDialog
              closeSettings={closeSettings}
              modelSelected={modelSelected}
              parameters={parameters}
              setModelSelected={setModelSelected}
              setParameters={setParameters}
              settingsOpened={settingsOpened}
            />
          )}
        </Box>
        <Footer footerHeight={FOOTER_HEIGHT} />
      </Box>
    </>
  );
}
