import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

import { SmartAssistantTemplate } from "./SmartAssistantTemplate";
import { Parameters } from "../../utils/types/parameters";
import { AssistantFromBFF } from "../../utils/types/assistant";
import { formatConversationParameters } from "../../utils/formatConversationParameters";

export interface SmartAssistantsProps extends PropsWithChildren {
  readonly smartAssistants: AssistantFromBFF[];
  readonly clickAssistant: (
    title: string,
    question: string,
    parameters: Parameters,
    tags: string[],
    url: string,
    ragId: string
  ) => void;
}

export function SmartAssistants({
  smartAssistants,
  clickAssistant,
}: SmartAssistantsProps): JSX.Element {
  return (
    <Box className="flex flex-wrap w-full items-center justify-center gap-[40px]">
      {smartAssistants.map((assistant: AssistantFromBFF, index: number) => {
        return (
          <SmartAssistantTemplate
            key={index}
            title={assistant.title}
            question={assistant.question}
            clickAssistant={clickAssistant}
            parameters={formatConversationParameters(assistant.parameters)}
            tags={assistant.rag.rag_tags}
            url={assistant.rag.rag_url}
            ragId={assistant.rag.rag_id}
            knowledgeUrl={assistant.rag.knowledge_url}
          />
        );
      })}
    </Box>
  );
}
