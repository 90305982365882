import { Avatar, Box, Button } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { LogOutMenu } from "./LogOutMenu";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const avatarSx = {
  bgcolor: "black",
  border: "solid 1px white",
  fontSize: "16px",
  fontWeight: "700",
  fontFamily: "Ubuntu",
};

export interface LogProps extends PropsWithChildren {
  readonly headerHeight: number;
}

export function Log({ headerHeight }: LogProps): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { accounts } = useMsal();
  const fullName = isAuthenticated ? accounts[0].name ?? "" : "";
  const firstname = fullName?.split(" ")[0] ?? "";
  const lastname = fullName?.split(" ")[1] ?? "";

  return (
    <Box>
      {isAuthenticated && (
        <div className="flex justify-end">
          <Button
            onClick={handleClick}
            sx={{
              textTransform: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              ":hover": {
                bgcolor: "rgba(0,0,0,0.04)",
              },
            }}
          >
            <Box
              sx={{
                padding: "2px",
                borderRadius: "30px",
                backgroundImage:
                  "linear-gradient(283deg, #05E4C5, #006CFA 25%, #711CFF 50%, #FF007D 75%, #F72717)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar alt={fullName ?? "avatar_thumbnail"} sx={avatarSx}>
                {`${firstname.slice(0, 1)}${lastname.slice(0, 1)}`}
              </Avatar>
            </Box>
          </Button>

          <LogOutMenu
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
          />
        </div>
      )}
    </Box>
  );
}
