import { Box, SxProps } from "@mui/material";
import { PropsWithChildren } from "react";

export interface CustomIconProps extends PropsWithChildren {
  readonly filename: string;
  readonly sx?: SxProps;
  readonly white?: boolean;
}

export function CustomIcon({
  filename,
  sx = {},
  white = false,
}: CustomIconProps): JSX.Element {
  return (
    <Box className="flex" sx={{ width: "24px", height: "24px", ...sx }}>
      <img
        alt={filename}
        draggable={false}
        src={white ? `icons/white/${filename}` : `icons/dark/${filename}`}
      />
    </Box>
  );
}
