// src/components/ConfirmUploadDialog.tsx

import { Button, Dialog, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { useUserProfile } from "../../hooks/useUserProfile";
import { pushDataLayerEvent } from "../../utils/dataLayerUtils";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey600 = COLORS.BASE.GREY[600];
const grey700 = COLORS.BASE.GREY[700];

const titleSx = {
  color: greyDark,
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
};

const descriptionSx = {
  color: grey700,
  textAlign: "center" as const,
  fontSize: "16px",
  lineHeight: "24px",
};

const uploadButtonSx = {
  textTransform: "none" as const,
  padding: "12px 20px",
  borderRadius: "12px",
  backgroundColor: greyDark,
  color: "white",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { backgroundColor: grey600 },
};
const cancelButtonSx = {
  textTransform: "none" as const,
  padding: "12px 20px",
  borderRadius: "12px",
  color: greyDark,
  border: `2px solid ${greyDark}`,
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { borderColor: grey600, color: grey600 },
};

export interface ConfirmUploadDialogProps extends PropsWithChildren {
  readonly handleCancelUpload: () => void;
  readonly handleUpload: () => void;
  readonly openConfirmation: boolean;
  readonly file: File | null; // Added to get file information
}

export function ConfirmUploadDialog({
  handleCancelUpload,
  handleUpload,
  openConfirmation,
  file,
}: ConfirmUploadDialogProps): JSX.Element {
  // Use the custom hook to get user profile data
  const userProfile = useUserProfile();

  function handleConfirmUpload() {
    if (file && userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      // Extract file type from the file object
      const fileType = file.type.split("/").pop() ?? "unknown";

      pushDataLayerEvent({
        event: "upload",
        website,
        env_work: envWork,
        file_type: fileType,
        user_data: userData,
      });
    }

    // Call the original handleUpload function
    handleUpload();
  }

  return (
    <Dialog
      open={openConfirmation}
      onClose={handleCancelUpload}
      sx={{ ".MuiPaper-root": { width: "500px", borderRadius: "16px" } }}
    >
      <div className="flex flex-col ">
        <div className="flex justify-between px-4 pt-4">
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCancelUpload}
          >
            <CustomIcon filename="arrow-left-base-grey-dark.svg" />
          </Button>
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCancelUpload}
          >
            <CustomIcon filename="close-base-grey-dark.svg" />
          </Button>
        </div>

        <div className="flex flex-col gap-6 px-[40px] pb-[40px]">
          <div className="flex flex-col items-center justify-center">
            <Typography sx={titleSx}>Upload document</Typography>
            <Typography sx={descriptionSx}>
              The document should be a text-only PDF and cannot exceed 10 MB.
            </Typography>
            <Typography sx={descriptionSx}>
              Are you sure you want to upload this document?
            </Typography>
          </div>

          <div className="flex flex-col items-center justify-center gap-3 px-[90px]">
            <Button
              className="flex gap-2"
              sx={uploadButtonSx}
              fullWidth
              onClick={handleConfirmUpload}
            >
              <CustomIcon filename="document-upload.svg" white />
              Upload
            </Button>
            <Button
              className="flex gap-2"
              sx={cancelButtonSx}
              fullWidth
              onClick={handleCancelUpload}
            >
              <CustomIcon filename="close-circle-base-grey-dark.svg" />
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
