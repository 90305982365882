import { axiosClient } from ".";

export async function renameConversation(
  conversationId: string,
  title: string
): Promise<string> {
  const body = {
    id: conversationId,
    title: title,
    rag_id: "",
  };

  return new Promise((resolve) => {
    axiosClient
      .put("/Edenchat/RenameHistory", body)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        resolve(conversationId);
      })
      .catch((error) => console.error(error));
  });
}
