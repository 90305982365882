// src/utils/dataLayerUtils.ts

import TagManager from "react-gtm-module";

interface UserData {
  user_id: string;
  user_location: string;
  user_language: string;
  user_bu: string;
  user_company: string;
  user_jobtitle: string;
}

interface DataLayerEventArgs {
  event: string;
  website: string;
  env_work: string;
  page_path?: string;
  button_title?: string;
  assistant_name?: string;
  file_type?: string;
  gpt_version?: string;
  user_has_access?: boolean;
  temperature?: string;
  response_length?: string;
  user_data: UserData;
}

export function pushDataLayerEvent(args: DataLayerEventArgs) {
  const dataLayerArgs: any = {
    event: args.event,
    website: args.website,
    env_work: args.env_work,
    user_data: args.user_data,
  };

  // Conditionally add optional fields
  if (args.page_path) dataLayerArgs.page_path = args.page_path;
  if (args.button_title) dataLayerArgs.button_title = args.button_title;
  if (args.assistant_name) dataLayerArgs.assistant_name = args.assistant_name;
  if (args.file_type) dataLayerArgs.file_type = args.file_type;
  if (args.gpt_version) dataLayerArgs.gpt_version = args.gpt_version;
  if (args.temperature) dataLayerArgs.temperature = args.temperature;
  if (args.response_length)
    dataLayerArgs.response_length = args.response_length;

  TagManager.dataLayer({ dataLayer: dataLayerArgs });
}
