import { getMsalAccessToken } from "../utils/sessionStorageUtils";
import axios, { AxiosInstance } from "axios";

const axiosClient = (): AxiosInstance =>
  axios.create({
    baseURL: process.env.REACT_APP_BFF_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getMsalAccessToken()}`,
    },
  });

export const hasSharepointAccess = async (
  sharepointUrl: string
): Promise<boolean> => {
  try {
    const graphEndpoint = getGraphEndpoint(sharepointUrl);
    if (graphEndpoint) {
      await axiosClient().get(graphEndpoint);
      return true;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
};

const getGraphEndpoint = (sharepointUrl: string) => {
  const regex = /https:\/\/([^/]+)\/?.*\/sites\/([^/]+)/;
  const match = sharepointUrl.match(regex);
  if (match && match.length > 2) {
    const domain = match[1];
    const siteName = match[2];
    return `https://graph.microsoft.com/v1.0/sites/${domain}:/sites/${siteName}`;
  }
};
