import { ConversationHistory } from "../utils/types/conversationHistory";
import { axiosClient } from "./";

export async function getHistory(): Promise<ConversationHistory[]> {
  const _params = {
    offset: "0",
    window_size: "100",
  };

  return new Promise((resolve) => {
    axiosClient
      .post(
        "/Edenchat/GetLastNHistory",
        {},
        {
          params: _params,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        const allHistory: ConversationHistory[] = response.data;
        resolve(sortHistory(allHistory));
      })
      .catch((error) => console.error(error));
  });
}

function sortHistory(
  unsortedHistory: ConversationHistory[]
): ConversationHistory[] {
  return unsortedHistory;
}
