import React from "react";

interface MessageBoxToolbarProps {
  children?: React.ReactNode;
  showActions?: boolean;
}

const MessageBoxToolbar: React.FC<MessageBoxToolbarProps> = ({
  children,
  showActions,
}) => {
  return (
    <div
      className={`flex flex-row p-1  items-center min-h-[38px] min-w-[100px] justify-end`}
    >
      {showActions ? children : null}
    </div>
  );
};

export default MessageBoxToolbar;
