import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

import COLORS from "../utils/styles/colors";
import { ModelsType } from "../utils/types/models";

const grey600 = COLORS.BASE.GREY[600];
export interface ChooseModelProps extends PropsWithChildren {
  readonly modelSelected: ModelsType;
}

export function ChooseModel({ modelSelected }: ChooseModelProps): JSX.Element {
  return (
    <Box className="absolute right-2">
      <Typography
        className="flex items-center"
        sx={{
          color: grey600,
          padding: "8px 12px",
        }}
      >
        {modelSelected.label}
      </Typography>
    </Box>
  );
}
