import { mockConfig } from "../mock-data/mockConfig";
import { models, ModelsType } from "./models";

export interface Parameters {
  llm?: ModelsType;
  temperature?: string;
  systemPrompt?: string;
  maxOutputToken?: string;
}
const defaultModel = models.find(
  (model: ModelsType) => model.llm_name === mockConfig.default.llm_name
);
export const llmDEFAULT: ModelsType = defaultModel!;
export const temperatureDEFAULT: string = String(
  mockConfig.default.temperature
);
export const systemPromptDEFAULT: string = mockConfig.default.user_prompt;
export const maxOutputTokenDEFAULT: string = String(
  mockConfig.default.max_output_tokens
);
export const maxOutputTokenMIN: string = String(
  mockConfig.settings_parameters.max_output_tokens.min
);
export const maxOutputTokenMEDIUM: string = String(
  mockConfig.settings_parameters.max_output_tokens.medium
);
export const maxOutputTokenMAX: string = String(
  mockConfig.settings_parameters.max_output_tokens.max
);
export const temperatureMIN: string = String(
  mockConfig.settings_parameters.temperature.min
);
export const temperatureMAX: string = String(
  mockConfig.settings_parameters.temperature.max
);
export const temperatureSTEP: string = String(
  mockConfig.settings_parameters.temperature.step
);

export const defaultParameters: Parameters = {
  llm: llmDEFAULT,
  temperature: temperatureDEFAULT,
  systemPrompt: systemPromptDEFAULT,
  maxOutputToken: maxOutputTokenDEFAULT,
};
