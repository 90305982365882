// src/authConfig.ts

import { Configuration } from "@azure/msal-browser";

const tenantId = process.env.REACT_APP_ENTRA_TENANT_ID!;
const clientId = process.env.REACT_APP_WEBAPP_ID!;
const redirectUri = process.env.REACT_APP_FRONT_URL!;
const apiScope = `api://${clientId}/user_impersonation`;

// Scopes pour Microsoft Graph API
const graphScopes = ["User.Read", "Sites.Read.All"];

export const msalConfiguration: Configuration = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

// Scopes pour l'authentification de l'utilisateur (ID token)
export const loginRequest = {
  scopes: ["openid", "profile", "offline_access"],
};

// Scopes pour accéder à votre API backend (access token)
export const apiTokenRequest = {
  scopes: [apiScope],
};

// Scopes pour accéder à Microsoft Graph API (access token)
export const graphTokenRequest = {
  scopes: graphScopes,
};
