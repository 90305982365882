import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Box, Button, Typography } from "@mui/material";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui/CustomIcon";
import { NormalAssistants } from "../assistant-library/NormalAssistants";
import { Parameters } from "../../utils/types/parameters";
import { AssistantFromBFF } from "../../utils/types/assistant";
import { AssistantTags } from "../assistant-library/AssistantTags";

const grey200 = COLORS.BASE.GREY[200];
const grey400 = COLORS.BASE.GREY[400];
const greyDark = COLORS.BASE.GREY.DARK;
const blackWhiteBlack = COLORS.BLACK_WHITE.BLACK;

const welcomeSx = {
  background: "linear-gradient(90deg, #1077FF 0%, #9E00FF 100%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  textAlign: "center",
  fontSize: "54px",
  fontWeight: "700",
  lineHeight: "64px",
};

const strongTitleSx = {
  color: blackWhiteBlack,
  textAlign: "center",
  fontWeight: 700,
  lineHeight: "28px",
  fontSize: {
    xs: "16px",
    sm: "18px",
    md: "20px",
  },
};

const urlSx = {
  color: grey400,
  fontSize: "11px",
  lineHeight: "16px",
  textAlign: "center",
};

const embeddingSx = {
  color: blackWhiteBlack,
  textAlign: "center",
};

const seeMoreButtonSx = {
  maxWidth: "140px",
  textTransform: "none",
  padding: "8px 16px",
  borderRadius: "12px",
  color: greyDark,
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "24px",
  ":hover": { backgroundColor: grey200 },
};

// Fonction de mélange Fisher-Yates
function shuffleArray<T>(array: T[]): T[] {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
}

export interface WelcomeInfosProps extends PropsWithChildren {
  readonly assistants: AssistantFromBFF[];
  readonly assistantTitle: string;
  readonly assistantTags: string[];
  readonly assistantUrl: string;
  readonly embeddingInProgress: boolean;
  readonly file: File | null;
  readonly normalAssistant: boolean;
  readonly setAssistantTitle: Dispatch<SetStateAction<string>>;
  readonly setNormalAssistant: Dispatch<SetStateAction<boolean>>;
  readonly setParameters: Dispatch<SetStateAction<Parameters>>;
  readonly setText: Dispatch<SetStateAction<string>>;
  readonly smartAssistant: boolean;
}

export function WelcomeInfos({
  assistants,
  assistantTitle,
  assistantTags,
  assistantUrl,
  embeddingInProgress,
  file,
  normalAssistant,
  setAssistantTitle,
  setNormalAssistant,
  setParameters,
  setText,
  smartAssistant,
}: WelcomeInfosProps): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const handleSeeMore = () => setIsExpanded(true);
  const handleSeeLess = () => setIsExpanded(false);

  const refScrollTitle = useRef<null | HTMLDivElement>(null);

  const shuffledAssistants = useMemo(
    () => shuffleArray(assistants),
    [assistants]
  );

  function handleClickNormal(
    title: string,
    question: string,
    parameters: Parameters
  ) {
    setAssistantTitle(title);
    setParameters(parameters);
    setText(question);
    setNormalAssistant(true);
  }

  function scrollToTitle() {
    refScrollTitle.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToTitle();
  }, []);

  return (
    <div ref={refScrollTitle} className="flex flex-col items-center gap-8">
      {(normalAssistant || smartAssistant) && (
        <>
          <Typography sx={welcomeSx}>{assistantTitle}</Typography>
          {smartAssistant && (
            <div className="flex flex-col items-center gap-4">
              <AssistantTags tags={assistantTags} />
              <Typography sx={urlSx}>{assistantUrl}</Typography>
            </div>
          )}
          <Box
            className="flex items-center justify-center"
            sx={{ height: "300px", width: "300px" }}
          >
            <img alt="happy gif" draggable={false} src={"/happy.gif"} />
          </Box>
        </>
      )}

      {!smartAssistant && !normalAssistant && (
        <>
          <Typography sx={welcomeSx}> Welcome to EdenChat !</Typography>
          <Box className="flex flex-col gap-4 w-full px-4 md:px-8 lg:px-16 ">
            <Typography sx={strongTitleSx}>
              This chatbot is for internal use only, remember your input must be
              relevant and not include any information that could be considered
              as confidential, sensitive or insulting. Outputs generated by the
              AI should be reviewed by users accordingly.
              <br />
              <span>
                <span className="font-bold" style={{ color: "#0062FF" }}>
                  {"Take a tour and discover the new features: "}
                </span>
                <u>
                  <a
                    href="https://edenred.sharepoint.com/sites/AI/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FAI%2FShared%20Documents%2FEdenChatProd%2FQuickStart%5FEdenChat%2Epdf&parent=%2Fsites%2FAI%2FShared%20Documents%2FEdenChatProd"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#0062FF" }}
                    className="font-bold"
                  >
                    Quick Start
                  </a>
                </u>{" "}
                🚀
              </span>
            </Typography>

            {embeddingInProgress && (
              <>
                <Typography sx={embeddingSx}>
                  The document should be a text-only PDF and cannot exceed 10
                  MB. Embedding is in progress, please wait for the completion.
                </Typography>
                <div className="flex justify-center">
                  <Box
                    sx={{
                      height: "80px",
                      width: "80px",
                      marginLeft: "-10px",
                    }}
                  >
                    <img
                      alt="loader gif"
                      draggable={false}
                      src={"/loaderEdenred.gif"}
                      style={{ objectFit: "contain" }}
                    />
                  </Box>
                </div>
              </>
            )}
          </Box>

          {!!assistants.length && !embeddingInProgress && !file && (
            <div className="flex flex-wrap w-full h-full justify-center gap-8">
              <NormalAssistants
                assistants={
                  isExpanded
                    ? shuffledAssistants
                    : shuffledAssistants.slice(0, 3)
                }
                clickAssistant={handleClickNormal}
              />
              <Button
                className="flex gap-2"
                sx={seeMoreButtonSx}
                onClick={isExpanded ? handleSeeLess : handleSeeMore}
              >
                <CustomIcon
                  filename={`${isExpanded ? "minus" : "add"}-base-grey-dark.svg`}
                />
                {isExpanded ? "See less" : "See more"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
