import { Button, Dialog, TextField, Typography } from "@mui/material";
import { KeyboardEvent, PropsWithChildren, useState } from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey600 = COLORS.BASE.GREY[600];
const grey700 = COLORS.BASE.GREY[700];

const titleSx = {
  color: greyDark,
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
};

const descriptionSx = {
  color: grey700,
  fontSize: "16px",
  lineHeight: "24px",
};

const renameButtonSx = {
  textTransform: "none",
  padding: "12px 20px",
  borderRadius: "12px",
  backgroundColor: greyDark,
  color: "white",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { backgroundColor: grey600 },
};
const cancelButtonSx = {
  textTransform: "none",
  padding: "12px 20px",
  borderRadius: "12px",
  color: greyDark,
  border: `2px solid ${greyDark}`,
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { borderColor: grey600, color: grey600 },
};

export interface RenameDialogProps extends PropsWithChildren {
  readonly applyRename: (_newTitle: string) => void;
  readonly handleCloseRename: () => void;
  readonly oldTitle: string;
  readonly openRename: boolean;
}

export function RenameDialog({
  applyRename,
  handleCloseRename,
  oldTitle,
  openRename,
}: RenameDialogProps): JSX.Element {
  const [newTitle, setNewTitle] = useState<string>(oldTitle);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTitle(event.target.value);
  };

  async function handleSubmit() {
    if (newTitle.trim() === "") return null;
    applyRename(newTitle);
  }

  function filterKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
    if (evt.key === "Enter") {
      handleSubmit();
      evt.preventDefault();
    }
    evt.stopPropagation();
  }

  return (
    <Dialog
      open={openRename}
      onClose={handleCloseRename}
      sx={{ ".MuiPaper-root": { width: "500px", borderRadius: "16px" } }}
    >
      <div className="flex flex-col ">
        <div className="flex justify-between px-4 pt-4">
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCloseRename}
          >
            <CustomIcon filename="arrow-left-base-grey-dark.svg" />
          </Button>
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCloseRename}
          >
            <CustomIcon filename="close-base-grey-dark.svg" />
          </Button>
        </div>

        <div className="flex flex-col gap-6 px-[40px] pb-[40px]">
          <div className="flex flex-col items-center justify-center">
            <Typography sx={titleSx}>Rename conversation</Typography>
            <Typography sx={descriptionSx}>
              Are you sure to rename this conversation ?
            </Typography>
          </div>
          <TextField
            placeholder="Choose a new title"
            value={newTitle}
            onChange={handleChange}
            onKeyDown={filterKeyDown}
          />

          <div className="flex flex-col items-center justify-center gap-3 px-[90px]">
            <Button
              className="flex gap-2"
              sx={renameButtonSx}
              fullWidth
              onClick={handleSubmit}
            >
              <CustomIcon filename="edit.svg" white />
              Rename
            </Button>
            <Button
              className="flex gap-2"
              sx={cancelButtonSx}
              fullWidth
              onClick={handleCloseRename}
            >
              <CustomIcon filename="close-circle-base-grey-dark.svg" />
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
