import { PropsWithChildren } from "react";
import { Menu, MenuItem, Typography, Box, Divider } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";

const grey100 = COLORS.BASE.GREY[100];
const grey600 = COLORS.BASE.GREY[600];
const shadowColor = COLORS.LOGOUT_SHADOW;

const menuSx = {
  "& .MuiPaper-root": {
    boxShadow: `0px 16px 32px 8px ${shadowColor}`,
    borderRadius: "24px",
    padding: "20px",
    width: "310px",
  },
  "& .MuiList-root": { padding: "0px" },
  "& .MuiMenuItem-root": {
    padding: "12px",
    color: grey600,
    borderRadius: "12px",
    ":hover": { backgroundColor: grey100 },
  },
};

export interface LogOutMenuProps extends PropsWithChildren {
  readonly anchorEl: HTMLElement | null;
  readonly open: boolean;
  readonly handleClose: () => void;
}

export function LogOutMenu({
  anchorEl,
  open,
  handleClose,
}: LogOutMenuProps): JSX.Element {
  const { instance, accounts } = useMsal();

  const handleLogoutPopup = () => {
    instance
      .logoutPopup({
        mainWindowRedirectUri: "/",
        account: instance.getActiveAccount(),
      })
      .catch((error) => console.error(error));
  };

  const fullName = accounts[0]?.name ?? "";
  const [firstname, lastname] = fullName.split(" ");

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handleClose}
      open={open}
      sx={menuSx}
    >
      <Box className="flex flex-col gap-2 mb-2">
        <Typography variant="subtitle1" fontWeight="600">
          {firstname} {lastname}
        </Typography>
        <Divider />
      </Box>

      <MenuItem className="flex gap-[10px]" onClick={handleLogoutPopup}>
        <CustomIcon filename="logout-base-grey-dark.svg" />
        <Typography>Log out</Typography>
      </MenuItem>
    </Menu>
  );
}
