import { defaultParameters, Parameters } from "../utils/types/parameters";
import { axiosClient } from "./";

export async function getAnswer(
  question: string,
  userToken: string,
  conversationId: string,
  parameters: Parameters,
  ragId: string,
  isDocumentUploaded: boolean
): Promise<string> {
  const data = {
    question: question,
    conversation_id: conversationId,
    rag_id: ragId,
    is_document_uploaded: isDocumentUploaded,
  };

  let parametersBFF = {};

  if (parameters.llm !== defaultParameters.llm) {
    parametersBFF = { ...parametersBFF, llm_name: parameters.llm?.llm_name };
  }

  if (parameters.temperature !== defaultParameters.temperature) {
    parametersBFF = { ...parametersBFF, temperature: parameters.temperature };
  }

  if (parameters.systemPrompt !== defaultParameters.systemPrompt) {
    parametersBFF = {
      ...parametersBFF,
      system_prompt: parameters.systemPrompt,
    };
  }

  if (parameters.maxOutputToken !== defaultParameters.maxOutputToken) {
    parametersBFF = {
      ...parametersBFF,
      max_output_token: parameters.maxOutputToken,
    };
  }
  const body =
    Object.keys(parametersBFF).length === 0
      ? data
      : { ...data, parameters: parametersBFF };

  return new Promise((resolve) => {
    axiosClient
      .post("/Edenchat/GetAnswer", body)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        resolve(String(response.data.answer));
      })
      .catch((error) => console.error(error));
  });
}
