import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

import { AssistantTemplate } from "./AssistantTemplate";
import { Parameters } from "../../utils/types/parameters";
import { AssistantFromBFF } from "../../utils/types/assistant";
import { formatConversationParameters } from "../../utils/formatConversationParameters";

export interface NormalAssistantsProps extends PropsWithChildren {
  readonly assistants: AssistantFromBFF[];
  readonly clickAssistant: (
    title: string,
    question: string,
    parameters: Parameters
  ) => void;
}

export function NormalAssistants({
  assistants,
  clickAssistant,
}: NormalAssistantsProps): JSX.Element {
  return (
    <Box className="flex flex-wrap w-full items-center justify-center gap-[40px]">
      {assistants.map((assistant: AssistantFromBFF, index: number) => {
        return (
          <AssistantTemplate
            key={index}
            title={assistant.title}
            question={assistant.question}
            clickAssistant={clickAssistant}
            parameters={formatConversationParameters(assistant.parameters)}
          />
        );
      })}
    </Box>
  );
}
