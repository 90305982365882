import { AssistantFromBFF } from "../utils/types/assistant";
import { axiosClient } from "./";

export async function getAssistants(
  userToken: string
): Promise<AssistantFromBFF[]> {
  return new Promise((resolve) => {
    axiosClient
      .post("/Edenchat/GetAllAssistants", {})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        const assistantsFromBff: AssistantFromBFF[] = response.data;
        resolve(assistantsFromBff);
      })
      .catch((error) => console.error(error));
  });
}
