export function formatDate(dateString: string): string {
  const date = new Date(dateString);

  const optionsDate: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
  };

  const optionsTime: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", optionsDate);
  const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

  return `${formattedDate} at ${formattedTime}`;
}
