import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useMsal } from "@azure/msal-react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { AssistantFromBFF } from "../utils/types/assistant";
import { TopButtons } from "./TopButtons";
import { AssistantLibrary } from "./assistant-library/AssistantLibrary";
import { ChatInput, ChatMessages, WelcomeInfos } from "./chat";
import { getSmartAssistants } from "../api/getSmartAssistants";
import { getAssistants } from "../api/getAssistants";
import { renameConversation } from "../api/renameConversation"; // Import de la fonction renameConversation
import { Message } from "../utils/types/message";
import { ModelsType } from "../utils/types/models";
import { Parameters } from "../utils/types/parameters";
import { ConversationHistory } from "../utils/types/conversationHistory";

const CHAT_PADDING_OPENED = 40;
const CHAT_PADDING_CLOSEED = 128;
const CHAT_INNER_PADDING_OPENED = 20;
const CHAT_INNER_PADDING_CLOSED = 20;

export interface MainContentProps extends PropsWithChildren {
  readonly assistantTags: string[];
  readonly assistantTitle: string;
  readonly assistantUrl: string;
  readonly convId: string | null;
  readonly convExited: boolean;
  readonly file: File | null;
  readonly handleNewChat: () => void;
  readonly handleOpenLibrary: (ragId?: string) => void;
  readonly historyDrawerWidth: number;
  readonly historyOpened: boolean;
  readonly historyUpdate: () => Promise<void>;
  readonly isEmpty: boolean;
  readonly displayLoader: boolean;
  readonly libraryOpened: boolean;
  readonly messages: Message[];
  readonly modelSelected: ModelsType;
  readonly normalAssistant: boolean;
  readonly openHistory: () => void;
  readonly openSettings: () => void;
  readonly parameters: Parameters;
  readonly ragId: string;
  readonly setAssistantTags: Dispatch<SetStateAction<string[]>>;
  readonly setAssistantTitle: Dispatch<SetStateAction<string>>;
  readonly setAssistantUrl: Dispatch<SetStateAction<string>>;
  readonly setConvId: Dispatch<SetStateAction<string | null>>;
  readonly setConvExited: Dispatch<SetStateAction<boolean>>;
  readonly setFile: Dispatch<SetStateAction<File | null>>;
  readonly setFirstAnswer: Dispatch<SetStateAction<boolean>>;
  readonly setIsEmpty: Dispatch<SetStateAction<boolean>>;
  readonly setDisplayLoader: Dispatch<SetStateAction<boolean>>;
  readonly setLibraryOpened: Dispatch<SetStateAction<boolean>>;
  readonly setMessages: Dispatch<SetStateAction<Message[]>>;
  readonly setNormalAssistant: Dispatch<SetStateAction<boolean>>;
  readonly setParameters: Dispatch<SetStateAction<Parameters>>;
  readonly setRagId: Dispatch<SetStateAction<string>>;
  readonly setSmartAssistant: Dispatch<SetStateAction<boolean>>;
  readonly setText: Dispatch<SetStateAction<string>>;
  readonly setWaitingForAnswer: Dispatch<SetStateAction<boolean>>;
  readonly smartAssistant: boolean;
  readonly text: string;
  readonly transition: string;
  readonly waitingForAnswer: boolean;
  readonly history: ConversationHistory[];
  initialRagId?: string | null;
}

export function MainContent({
  assistantTags,
  assistantTitle,
  assistantUrl,
  convId,
  convExited,
  file,
  handleNewChat,
  handleOpenLibrary,
  historyDrawerWidth,
  historyOpened,
  historyUpdate,
  isEmpty,
  displayLoader,
  libraryOpened,
  messages,
  modelSelected,
  normalAssistant,
  openHistory,
  openSettings,
  parameters,
  ragId,
  setAssistantTags,
  setAssistantTitle,
  setAssistantUrl,
  setConvId,
  setConvExited,
  setFile,
  setFirstAnswer,
  setIsEmpty,
  setDisplayLoader,
  setLibraryOpened,
  setMessages,
  setNormalAssistant,
  setParameters,
  setRagId,
  setSmartAssistant,
  setText,
  setWaitingForAnswer,
  smartAssistant,
  text,
  transition,
  waitingForAnswer,
  initialRagId,
  history,
}: Readonly<MainContentProps>) {
  const { accounts } = useMsal();
  const userToken = accounts[0].idToken!;

  const chatPadding = historyOpened
    ? CHAT_PADDING_OPENED
    : CHAT_PADDING_CLOSEED;

  const chatInnerPadding = historyOpened
    ? CHAT_INNER_PADDING_OPENED
    : CHAT_INNER_PADDING_CLOSED;

  const [assistants, setAssistants] = useState<AssistantFromBFF[]>([]);
  const [smartAssistants, setSmartAssistants] = useState<AssistantFromBFF[]>(
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [debugLogs, setDebugLogs] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showLogs, setShowLogs] = useState<boolean>(false);

  const scrollEndRef = useRef<null | HTMLDivElement>(null);

  const [embeddingInProgress, setEmbeddingInProgress] =
    useState<boolean>(false);
  const [historyLoading, setHistoryLoading] = useState<boolean>(true);

  function addLog(message: string) {
    setDebugLogs((prevLogs) => [...prevLogs, message]);
  }

  async function fetchAssistants() {
    addLog("Fetching assistants...");
    try {
      const dataAssistants: AssistantFromBFF[] = await getAssistants(userToken);
      addLog(`Fetched ${dataAssistants.length} assistants.`);
      const dataSmartAssistants: AssistantFromBFF[] =
        await getSmartAssistants(userToken);
      addLog(`Fetched ${dataSmartAssistants.length} smart assistants.`);
      setAssistants(dataAssistants);
      setSmartAssistants(dataSmartAssistants);
    } catch (error) {
      addLog(`Error fetching assistants: ${error}`);
    }
  }

  useEffect(() => {
    if (initialRagId?.length && initialRagId !== "0") {
      addLog(`initialRagId detected: ${initialRagId}, opening library...`);
      handleOpenLibrary(initialRagId);
    } else {
      addLog(`initialRagId is ${initialRagId}, no library open action.`);
    }
  }, [initialRagId, handleOpenLibrary]);

  const scrollToBottom = () =>
    scrollEndRef.current?.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    addLog("Component mounted, fetching assistants and updating history...");
    fetchAssistants()
      .then(async () => {
        addLog("Calling historyUpdate...");
        await historyUpdate();
        addLog("historyUpdate completed.");
        setHistoryLoading(false);
      })
      .catch((error) => {
        addLog(`Error in fetchAssistants or historyUpdate: ${error}`);
        setHistoryLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (convId) {
      addLog(`convId changed: ${convId}, updating history...`);
      setHistoryLoading(true);
      historyUpdate()
        .then(() => {
          addLog("historyUpdate after convId change completed.");
          setHistoryLoading(false);
        })
        .catch((error) => {
          addLog(`Error in historyUpdate after convId change: ${error}`);
          setHistoryLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convId]);

  useEffect(() => {
    if (!isEmpty) {
      addLog("Messages updated, scrolling to bottom...");
      scrollToBottom();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const currentConversation =
    !historyLoading && convId ? history.find((h) => h.id === convId) : null;
  const conversationTitle = currentConversation
    ? currentConversation.title
    : "";

  function formatLog(line: string) {
    if (line.includes("convId:")) {
      return <span className="text-blue-400">{line}</span>;
    } else if (line.includes("conversationTitle:")) {
      return <span className="text-green-400">{line}</span>;
    } else if (line.includes("history length:")) {
      return <span className="text-purple-400">{line}</span>;
    } else {
      return <span className="text-gray-300">{line}</span>;
    }
  }

  async function handleRenameConversation(convId: string, newTitle: string) {
    addLog(`Rename requested for convId: ${convId} to ${newTitle}`);
    await renameConversation(convId, newTitle);
    await historyUpdate();
  }

  return (
    <div
      className={`flex flex-col flex-grow ${transition} h-full relative`}
      style={{
        maxWidth: historyOpened
          ? `calc(100% - ${historyDrawerWidth}px)`
          : "100%",
      }}
    >
      <TopButtons
        handleNewChat={handleNewChat}
        handleOpenLibrary={handleOpenLibrary}
        openHistory={openHistory}
        historyOpened={historyOpened}
        isEmpty={isEmpty}
        libraryOpened={libraryOpened}
        modelSelected={modelSelected}
        transition={transition}
        conversationTitle={conversationTitle}
        convId={convId}
        handleRenameConversation={handleRenameConversation}
        historyUpdate={historyUpdate}
      />
      {/* ACTIVE FOR DEV MODE. Please don't delete this comment.
       <button
        onClick={() => setShowLogs(!showLogs)}
        className={`absolute top-4 right-4 z-50 p-2 rounded-full transition-colors duration-200 ${
          showLogs
            ? "bg-blue-500 hover:bg-blue-600"
            : "bg-gray-500 hover:bg-gray-600"
        }`}
      >
        {showLogs ? (
          <VisibilityIcon className="w-5 h-5 text-white" />
        ) : (
          <VisibilityOffIcon className="w-5 h-5 text-white" />
        )}
      </button> */}

      <div
        className={`flex flex-col h-full w-full items-center ${transition} overflow-y-auto gap-6`}
        style={{ padding: `40px ${chatPadding}px 8px` }}
      >
        {libraryOpened && (
          <AssistantLibrary
            assistants={assistants}
            setLibraryOpened={setLibraryOpened}
            setText={setText}
            setParameters={setParameters}
            setSmartAssistant={setSmartAssistant}
            setNormalAssistant={setNormalAssistant}
            setAssistantTitle={setAssistantTitle}
            setAssistantTags={setAssistantTags}
            setAssistantUrl={setAssistantUrl}
            setRagId={setRagId}
            smartAssistants={smartAssistants}
            initialRagId={initialRagId}
          />
        )}

        {!libraryOpened && isEmpty && (
          <WelcomeInfos
            assistants={assistants}
            assistantTitle={assistantTitle}
            assistantTags={assistantTags}
            assistantUrl={assistantUrl}
            embeddingInProgress={embeddingInProgress}
            file={file}
            normalAssistant={normalAssistant}
            setAssistantTitle={setAssistantTitle}
            setNormalAssistant={setNormalAssistant}
            setParameters={setParameters}
            setText={setText}
            smartAssistant={smartAssistant}
          />
        )}

        {!libraryOpened && !!messages && !isEmpty && (
          <ChatMessages
            chatInnerPadding={chatInnerPadding}
            displayLoader={displayLoader}
            messages={messages}
          >
            <div ref={scrollEndRef}></div>
          </ChatMessages>
        )}
      </div>

      {!libraryOpened && (
        <ChatInput
          chatPadding={chatPadding}
          convId={convId}
          convExited={convExited}
          embeddingInProgress={embeddingInProgress}
          file={file}
          setFirstAnswer={setFirstAnswer}
          isEmpty={isEmpty}
          displayLoader={displayLoader}
          messages={messages}
          openSettings={openSettings}
          parameters={parameters}
          ragId={ragId}
          setConvId={setConvId}
          setConvExited={setConvExited}
          setEmbeddingInProgress={setEmbeddingInProgress}
          setFile={setFile}
          setIsEmpty={setIsEmpty}
          setDisplayLoader={setDisplayLoader}
          setMessages={setMessages}
          setText={setText}
          setWaitingForAnswer={setWaitingForAnswer}
          smartAssistant={smartAssistant}
          text={text}
          transition={transition}
          waitingForAnswer={waitingForAnswer}
        />
      )}

      {/* DON'T TOUCH
       {showLogs && (
        <div className="absolute top-16 right-4 w-72 h-96 bg-gray-800 bg-opacity-90 border border-gray-700 rounded-lg p-2 overflow-auto z-40 font-mono text-sm">
          <p className="font-bold mb-2 text-gray-100">Debug Logs</p>
          {debugLogs.map((line, index) => (
            <div key={index}>{formatLog(line)}</div>
          ))}
        </div>
      )} */}
    </div>
  );
}
