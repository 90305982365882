import { axiosClient } from ".";

export async function deleteConversation(
  conversationId: string
): Promise<string> {
  const body = {
    id: conversationId,
    rag_id: "",
  };

  return new Promise((resolve) => {
    axiosClient
      .delete("/Edenchat/DeleteHistory", {
        data: body,
      })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        resolve(conversationId);
      })
      .catch((error) => console.error(error));
  });
}
