import { Box, Button, Typography } from "@mui/material";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useMsal } from "@azure/msal-react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui/";
import { HistorySettingsMenu } from "./HistorySettingsMenu";
import {
  ConversationHistory,
  ParametersFromBFF,
} from "../../utils/types/conversationHistory";
import { deleteConversation, renameConversation } from "../../api";
import { formatDate } from "../../utils/formatDate";
const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const grey400 = COLORS.BASE.GREY[400];
const grey600 = COLORS.BASE.GREY[600];
const greyDark = COLORS.BASE.GREY.DARK;
const defaultBlue = COLORS.SYSTEM.INFO.DEFAULT;
const white = COLORS.BLACK_WHITE.WHITE;
const white40 = COLORS.BASE.WHITE[40];

const drawerSx = {
  backgroundColor: grey100,
  paddingTop: "24px",
};

const closeButtonSx = {
  bgcolor: grey100,
  borderRadius: "8px",
  padding: "8px",
  ":hover": { bgcolor: grey200 },
  alignSelf: "start",
};

const NewChatButtonSx = {
  flexShrink: 0,
  backgroundColor: greyDark,
  color: white,
  fontWeight: "700",
  fontSize: "16px",
  fontFamily: "Ubuntu",
  fontStyle: "normal",
  lineHeight: "24px",
  textTransform: "none" as const,

  width: "100%",
  padding: "14px 0",
  borderRadius: "12px",

  textWrap: "nowrap" as const,
  overflow: "hidden",

  border: `2px solid ${greyDark}`,
  ":hover": { backgroundColor: grey600, borderColor: grey600 },
  ":focus": { border: `2px solid ${white40}` },
};

const assistantButtonSx = {
  flexShrink: 0,
  backgroundColor: white,
  color: greyDark,
  fontWeight: "700",
  fontSize: "16px",
  fontFamily: "Ubuntu",
  fontStyle: "normal",
  lineHeight: "24px",
  textTransform: "none" as const,

  width: "100%",
  padding: "8px 0",
  borderRadius: "12px",

  textWrap: "nowrap" as const,
  overflow: "hidden",

  border: `2px solid ${greyDark}`,
  ":hover": {
    color: grey600,
    border: `3px solid ${grey600}`,
    padding: "7px 0",
  },
  ":focus": {
    color: grey400,
    border: `2px solid ${grey400}`,
    padding: "8px 0",
  },
};

const conversationTitleSx = {
  color: grey400,
  fontSize: "13px",
  fontWeight: 700,
  lineHeight: "20px",
};

const activeSx = {
  flexShrink: 0,
  color: "white",
  backgroundColor: defaultBlue,
  borderRadius: "12px",
  overflow: "hidden",
};

const unActiveSx = {
  flexShrink: 0,
  borderBottom: `1px solid ${grey200}`,
  overflow: "hidden",
};

const titleSx = {
  maxWidth: "170px",
  textWrap: "nowrap" as const,
  overflow: "hidden",
  textOverflow: "ellipsis" as const,
};

const dateSx = {
  fontSize: "12px",
  lineHeight: "18px",
  textWrap: "nowrap" as const,
  overflow: "hidden",
  maxWidth: "170px",
};

const descriptionSx = {
  fontSize: "12px",
  lineHeight: "18px",
  textWrap: "nowrap" as const,
  overflow: "hidden",
  maxWidth: "170px",
};

export interface HistoryDrawerProps extends PropsWithChildren {
  readonly closeHistory: () => void;
  readonly convId: string | null;
  readonly drawerWidth: number;
  readonly firstAnswer: boolean;
  readonly handleClickConversation: (
    _convId: string,
    _parameters: ParametersFromBFF,
    _ragId: string
  ) => Promise<void>;
  readonly handleNewChat: () => void;
  readonly handleOpenLibrary: () => void;
  readonly history: ConversationHistory[];
  readonly historyOpened: boolean;
  readonly historyUpdate: () => void;
  readonly libraryOpened: boolean;
  readonly setFirstAnswer: Dispatch<SetStateAction<boolean>>;
  readonly transition: string;
}

export function HistoryDrawer({
  closeHistory,
  convId,
  drawerWidth,
  firstAnswer,
  handleClickConversation,
  handleNewChat,
  handleOpenLibrary,
  history,
  historyUpdate,
  historyOpened,
  libraryOpened,
  setFirstAnswer,
  transition,
}: HistoryDrawerProps): JSX.Element {
  const [oldTitle, setOldTitle] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElConvId, setAnchorElConvId] = useState<string>("");
  const open = Boolean(anchorEl);

  const handleCloseSettings = useCallback(() => setAnchorEl(null), []);

  const handleClickSettings = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      _anchorConvId: string,
      _oldTitle: string
    ) => {
      setAnchorEl(event.currentTarget);
      setAnchorElConvId(_anchorConvId);
      setOldTitle(_oldTitle);
    },
    []
  );

  const handleDeleteConversation = useCallback(
    async (_convId: string) => {
      await deleteConversation(_convId);
      if (convId === _convId) {
        handleNewChat();
      }
      historyUpdate();
    },
    [convId, handleNewChat, historyUpdate]
  );

  const handleRenameConversation = useCallback(
    async (_convId: string, title: string) => {
      await renameConversation(_convId, title);
      historyUpdate();
    },
    [historyUpdate]
  );

  const handleClickStartAssistant = useCallback(() => {
    handleOpenLibrary();
  }, [handleOpenLibrary]);

  useEffect(() => {
    if (firstAnswer) {
      setFirstAnswer(false);
      historyUpdate();
    }
  }, [firstAnswer, historyUpdate, setFirstAnswer]);

  const handleKeyDownConversation = (
    e: React.KeyboardEvent<HTMLDivElement>,
    hItem: ConversationHistory
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleClickConversation(hItem.id, hItem.parameters, hItem.rag_id);
    }
  };

  return (
    <Box
      className={`flex flex-col items-center ${transition} overflow-hidden gap-8`}
      sx={{
        ...drawerSx,
        width: historyOpened ? `${drawerWidth}px` : "0",
      }}
    >
      <div className="flex flex-col w-full gap-[10px] px-4">
        <div className="flex flex-col w-full gap-4">
          <Button
            className="flex gap-2"
            onClick={handleNewChat}
            sx={NewChatButtonSx}
          >
            New conversation
          </Button>

          {!libraryOpened && (
            <Button
              className="flex gap-2"
              onClick={handleClickStartAssistant}
              sx={assistantButtonSx}
            >
              Start with an assistant
            </Button>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full items-center overflow-y-auto px-4">
        <div className="flex flex-col w-full items-center gap-4">
          <Typography
            className="flex w-full justify-start"
            sx={conversationTitleSx}
          >
            Conversations
          </Typography>

          {!convId && !libraryOpened && (
            <Box className="flex w-full items-center gap-4" sx={activeSx}>
              <div className="p-3">
                <CustomIcon filename="chart-circle-1.svg" white />
              </div>
              <div className="flex flex-grow flex-col min-w-0">
                <Typography sx={titleSx}>New active conversation</Typography>
                <Typography sx={descriptionSx}>Active conversation</Typography>
              </div>
            </Box>
          )}

          {history &&
            history.length > 0 &&
            history.map((historyItem: ConversationHistory) => {
              const isActive = convId === historyItem.id;
              return (
                <Box
                  className="flex w-full items-center overflow-hidden gap-4"
                  key={historyItem.id}
                  sx={isActive ? activeSx : unActiveSx}
                  role="button"
                  tabIndex={0}
                  onClick={() =>
                    handleClickConversation(
                      historyItem.id,
                      historyItem.parameters,
                      historyItem.rag_id
                    )
                  }
                  onKeyDown={(e) => handleKeyDownConversation(e, historyItem)}
                >
                  <div className="p-3">
                    <CustomIcon
                      filename={`clock-2${isActive ? "" : "-base-grey-dark"}.svg`}
                      white={isActive}
                    />
                  </div>

                  <div className="flex flex-grow flex-col min-w-0">
                    <Typography sx={titleSx}>{historyItem.title}</Typography>
                    <Typography
                      sx={{
                        ...dateSx,
                        color: isActive ? "white" : grey400,
                      }}
                    >
                      {formatDate(historyItem.last_updated)}
                    </Typography>
                  </div>

                  <Button
                    className="p-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickSettings(e, historyItem.id, historyItem.title);
                    }}
                  >
                    <CustomIcon filename="more-3-grey400.svg" />
                  </Button>
                </Box>
              );
            })}
        </div>
      </div>
      <HistorySettingsMenu
        anchorEl={anchorEl}
        anchorElConvId={anchorElConvId}
        open={open}
        handleClose={handleCloseSettings}
        handleDeleteConversation={handleDeleteConversation}
        handleRenameConversation={handleRenameConversation}
        oldTitle={oldTitle}
      />
    </Box>
  );
}
