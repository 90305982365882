import { Box, Typography } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import TagManager from "react-gtm-module";

import COLORS from "../../utils/styles/colors";
import { LinkField } from "./LinkField";
import { mockConfig } from "../../utils/mock-data/mockConfig";

const grey200 = COLORS.BASE.GREY[200];
const grey400 = COLORS.BASE.GREY[400];

const quickStartUrl = mockConfig.external_urls.quick_start;
const privacyPolicyUrl = mockConfig.external_urls.privacy_policy;
const termsOfUseUrl = mockConfig.external_urls.terms_of_use;

const typographySx = {
  color: grey400,
  fontSize: "11px",
  lineHeight: "16px",
};

export interface FooterProps extends PropsWithChildren {
  readonly footerHeight: number;
}

export function Footer({ footerHeight }: FooterProps): JSX.Element {
  const { accounts, instance } = useMsal();
  const userId = accounts[0]?.homeAccountId ?? "";
  const userLanguage = navigator.language ?? "en";
  const [userLocation, setUserLocation] = useState("Unknown");
  const [userBusinessUnit, setUserBusinessUnit] = useState("Unknown");

  useEffect(() => {
    async function fetchUserProfile() {
      try {
        // Acquire the token silently
        const authResult = await instance.acquireTokenSilent({
          scopes: ["User.Read"],
          account: accounts[0],
        });

        const accessToken = authResult.accessToken;

        const response = await fetch("https://graph.microsoft.com/v1.0/me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user profile: ${response.statusText}`
          );
        }

        const userProfile = await response.json();

        // Access standard attributes
        const officeLocation = userProfile.officeLocation ?? "Unknown";
        const businessUnit = userProfile.jobTitle ?? "Unknown";

        setUserLocation(officeLocation);
        setUserBusinessUnit(businessUnit);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    }

    fetchUserProfile();
  }, [accounts, instance]);

  function handleCguClick() {
    const dataLayerArgs = {
      dataLayer: {
        event: "cgu",
        website: "EdenChat",
        button_title: "Terms and Conditions of use",
        env_work: process.env.NODE_ENV ?? "development",
        user_data: {
          user_id: userId,
          user_location: userLocation,
          user_language: userLanguage,
          user_bu: userBusinessUnit,
        },
      },
    };
    TagManager.dataLayer(dataLayerArgs);
  }

  return (
    <Box
      className="flex flex-row w-full justify-between py-6 px-5"
      sx={{ height: `${footerHeight}px`, borderTop: `1px solid ${grey200}` }}
    >
      <div className="flex gap-3 items-center">
        <Typography>Copyright © 2024 Edenred</Typography>•
        <LinkField href={quickStartUrl} label="Quick Start" />•
        <LinkField href={privacyPolicyUrl} label="Privacy Policy" />•
        <LinkField
          href={termsOfUseUrl}
          label="Terms and Conditions of use"
          onClick={handleCguClick}
        />
      </div>

      <Typography className="flex flex-end items-center" sx={typographySx}>
        AI generated content can be inaccurate or false
      </Typography>
    </Box>
  );
}
