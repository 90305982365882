// src/components/SettingsDialog.tsx

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { models, ModelsType } from "../../utils/types/models";
import {
  defaultParameters,
  llmDEFAULT,
  maxOutputTokenDEFAULT,
  maxOutputTokenMAX,
  maxOutputTokenMIN,
  Parameters,
  systemPromptDEFAULT,
  temperatureDEFAULT,
  temperatureMAX,
  temperatureMIN,
  temperatureSTEP,
} from "../../utils/types/parameters";
import { mockConfig } from "../../utils/mock-data/mockConfig";
import { useUserProfile } from "../../hooks/useUserProfile";
import { pushDataLayerEvent } from "../../utils/dataLayerUtils";

const lengthTabs = [
  { label: "Small", value: maxOutputTokenMIN },
  { label: "Medium", value: maxOutputTokenDEFAULT },
  { label: "Large", value: maxOutputTokenMAX },
];

const blackWhiteBlack = COLORS.BLACK_WHITE.BLACK;
const defaultBlue = COLORS.SYSTEM.INFO.DEFAULT;
const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const grey300 = COLORS.BASE.GREY[300];
const grey400 = COLORS.BASE.GREY[400];
const grey600 = COLORS.BASE.GREY[600];
const greyDark = COLORS.BASE.GREY.DARK;
const greyBackground = COLORS.BASE.GREY.BACKGROUND;
const white = COLORS.BLACK_WHITE.WHITE;
const white40 = COLORS.BASE.WHITE[40];

const selectionDetails = mockConfig.details.selection;
const tempDetails = mockConfig.details.temperature;
const responseLengthDetails = mockConfig.details.response_length;
const systemPromptDetails = mockConfig.details.system_prompt;

const dialogSx = {
  "& .MuiDialog-container": {
    alignItems: "start",
    justifyContent: "end",
  },
  "& .MuiPaper-root": {
    backgroundColor: greyBackground,
    borderRadius: "12px",
    padding: "12px",
    minWidth: "35%",
    minHeight: "80%",
  },
};

const titleSx = {
  color: blackWhiteBlack,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "28px",
  width: "170px",
};

const subTitleSx = {
  color: blackWhiteBlack,
  fontSize: "13px",
  fontWeight: "700",
  lineHeight: "20px",
};

const closeButtonSx = {
  padding: "6px",
  borderRadius: "8px",
  border: `2px solid ${greyDark}`,

  height: "40px",
  width: "40px",
  ":hover": { bgcolor: grey400 },
};

const numberTempMax = Number(temperatureMAX);
const numberTempMin = Number(temperatureMIN);
const numberTempStep = Number(temperatureSTEP);
const TempMarks = [
  {
    value: numberTempMin,
    label: temperatureMIN,
  },
  {
    value: numberTempMax,
    label: temperatureMAX,
  },
];

const tabsSx = {
  borderRadius: "92px",
  backgroundColor: grey100,
  padding: "8px",
  width: "100%",
  ".MuiTab-root": {
    borderRadius: "30px",
    padding: "8px 20px",
    color: greyDark,
    fontFamily: "Ubuntu",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "28px",
    textTransform: "none",
    flexGrow: 1,
    ":hover": { backgroundColor: grey200 },
  },
  ".Mui-selected": {
    backgroundColor: defaultBlue,
    color: `${white} !important`,
    pointerEvents: "none",
  },

  ".MuiTabs-indicator": {
    display: "none",
  },
};

const resetButtonSx = {
  padding: "14px",
  borderRadius: "12px",
  border: `2px solid ${greyDark}`,

  ":hover": { bgcolor: grey400 },
  ":disabled": { bgcolor: grey200, border: "none", padding: "16px" },
};

const applyButtonSx = {
  color: white,
  backgroundColor: greyDark,
  padding: "14px 0",
  borderRadius: "12px",
  border: `2px solid ${greyDark}`,
  ":hover": { backgroundColor: grey600, borderColor: grey600 },
  ":focus": { border: `2px solid ${white40}` },
};

export interface SettingsDialogProps extends PropsWithChildren {
  closeSettings: () => void;
  modelSelected: ModelsType;
  parameters: Parameters;
  setModelSelected: Dispatch<SetStateAction<ModelsType>>;
  setParameters: Dispatch<SetStateAction<Parameters>>;
  settingsOpened: boolean;
}

export function SettingsDialog({
  closeSettings,
  modelSelected,
  parameters,
  setModelSelected,
  setParameters,
  settingsOpened,
}: Readonly<SettingsDialogProps>): JSX.Element {
  const userProfile = useUserProfile();

  const [borderColor, setBorderColor] = useState<string>(grey300);
  const textfieldSx = {
    border: `2px solid ${borderColor}`,
    backgroundColor: white,
    borderRadius: "12px",
    padding: "16px",
    "& .MuiInputBase-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  };

  // State used in handleReset() to trigger useEffect
  const [reset, setReset] = useState<boolean>(false);

  function handleChangeModel(
    event: React.SyntheticEvent<Element, Event>,
    newModel: ModelsType
  ) {
    setModelSelected(newModel);
  }

  const [temp, setTemp] = useState<number>(Number(parameters?.temperature));
  function handleChangeTemp(event: Event, newTemp: number | number[]) {
    setTemp(newTemp as number);
  }

  const [tokens, setTokens] = useState<string>(parameters?.maxOutputToken!);
  function handleChangeLength(
    event: React.SyntheticEvent<Element, Event>,
    newLength: "100" | "800" | "4000"
  ) {
    setTokens(newLength);
  }

  const [systemPrompt, setSystemPrompt] = useState<string>(
    parameters?.systemPrompt!
  );
  function handleChangePrompt(event: React.ChangeEvent<HTMLInputElement>) {
    setSystemPrompt(event.target.value);
  }

  const isDefault =
    modelSelected === llmDEFAULT &&
    temp === Number(temperatureDEFAULT) &&
    tokens === maxOutputTokenDEFAULT &&
    systemPrompt === systemPromptDEFAULT;

  function saveParameters(): void {
    const currentParameters: Parameters = {
      llm: modelSelected,
      temperature: String(temp),
      systemPrompt: systemPrompt ? systemPrompt.trim() : "",
      maxOutputToken: tokens,
    };
    setParameters(currentParameters);
  }

  function handleApply(): void {
    saveParameters();

    if (userProfile) {
      const envWork = process.env.NODE_ENV ?? "development";
      const website = "EdenChat";

      const userData = {
        user_id: userProfile.userId,
        user_location: userProfile.userLocation,
        user_language: userProfile.userLanguage,
        user_bu: userProfile.userBusinessUnit,
        user_company: userProfile.userCompany,
        user_jobtitle: userProfile.userJobTitle,
      };

      pushDataLayerEvent({
        event: "apply_settings",
        website,
        env_work: envWork,
        gpt_version: modelSelected.llm_name || "Unknown",
        temperature: temp.toString() || "Unknown",
        response_length: tokens || "Unknown",
        user_data: userData,
      });
    }

    closeSettings();
  }

  function handleReset(): void {
    setParameters(defaultParameters);
    setReset(!reset);
  }

  useEffect(() => {
    setModelSelected(parameters.llm!);
    setTemp(Number(parameters.temperature!));
    setTokens(parameters.maxOutputToken!);
    setSystemPrompt(parameters.systemPrompt!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters, reset, settingsOpened]);

  return (
    <Dialog open={settingsOpened} onClose={closeSettings} sx={dialogSx}>
      <DialogTitle sx={{ padding: "8px 12px" }}>
        <div className="flex items-center justify-between">
          <Typography sx={titleSx}>Advanced Settings</Typography>
          <Button onClick={closeSettings} sx={closeButtonSx}>
            <CustomIcon filename="close-base-grey-dark.svg" />
          </Button>
        </div>
      </DialogTitle>

      <DialogContent sx={{ padding: "0 12px" }}>
        <div className="flex flex-col justify-center gap-4">
          <div className="flex items-center gap-[40px]">
            <div className="flex flex-col justify-center items-start gap-4">
              <Tooltip arrow title={selectionDetails}>
                <Typography sx={subTitleSx}>Selection</Typography>
              </Tooltip>
              <Tabs
                value={modelSelected}
                onChange={handleChangeModel}
                sx={tabsSx}
              >
                {models.map((model: ModelsType) => (
                  <Tab key={model.label} value={model} label={model.label} />
                ))}
              </Tabs>
            </div>

            <div className="flex flex-col flex-grow items-start pr-3 gap-6">
              <Tooltip arrow title={tempDetails}>
                <Typography sx={subTitleSx}>Temperature</Typography>
              </Tooltip>
              <Slider
                min={numberTempMin}
                max={numberTempMax}
                marks={TempMarks}
                step={numberTempStep}
                value={temp}
                onChange={handleChangeTemp}
                sx={{ color: defaultBlue }}
                valueLabelDisplay="auto"
              />
            </div>
          </div>

          <div className="flex flex-col justify-center items-start gap-4">
            <Tooltip arrow title={responseLengthDetails}>
              <Typography sx={subTitleSx}>Response length</Typography>
            </Tooltip>
            <Tabs value={tokens} onChange={handleChangeLength} sx={tabsSx}>
              {lengthTabs.map(({ label, value }) => (
                <Tab key={label} value={value} label={label} />
              ))}
            </Tabs>
          </div>

          <div className="flex flex-col justify-center items-start gap-2">
            <Tooltip arrow title={systemPromptDetails}>
              <Typography sx={subTitleSx}>System Prompt</Typography>
            </Tooltip>
            <TextField
              multiline
              fullWidth
              onChange={handleChangePrompt}
              onFocus={() => setBorderColor(defaultBlue)}
              onBlur={() => setBorderColor(grey300)}
              placeholder="You are an AI Assistant"
              value={systemPrompt}
              sx={textfieldSx}
            />
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <div className="flex w-full items-center gap-6">
          <Button
            className="flex items-center justify-center"
            disabled={isDefault}
            onClick={handleReset}
            sx={resetButtonSx}
          >
            <CustomIcon
              filename={`refresh-double${
                isDefault ? "-grey400" : "-base-grey-dark"
              }.svg`}
            />
          </Button>
          <Button
            className="flex flex-grow gap-2"
            sx={applyButtonSx}
            onClick={handleApply}
          >
            <CustomIcon filename="tick-circle.svg" white />
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
