// src/hooks/useUserProfile.ts

import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { setMsalAccessToken } from "../utils/sessionStorageUtils";

export interface UserProfile {
  userId: string;
  userLocation: string;
  userLanguage: string;
  userBusinessUnit: string;
  userCompany: string;
  userJobTitle: string;
}

export function useUserProfile() {
  const { accounts, instance } = useMsal();
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    if (accounts.length > 0) {
      const account = accounts[0];
      const userId = account.homeAccountId;
      const userLanguage = navigator.language || "en";

      const fetchUserProfile = async () => {
        let accessToken = "";

        try {
          // Try to acquire token silently
          const authResult = await instance.acquireTokenSilent({
            scopes: loginRequest.scopes, // Ensure 'User.Read' is included
            account: account,
          });
          accessToken = authResult.accessToken;
        } catch (error: any) {
          if (error instanceof InteractionRequiredAuthError) {
            // Prompt user to interactively acquire token
            try {
              const authResult = await instance.acquireTokenPopup({
                scopes: loginRequest.scopes,
                account: account,
              });
              accessToken = authResult.accessToken;
            } catch (popupError) {
              console.error(
                "Interactive token acquisition failed:",
                popupError
              );
              return;
            }
          } else {
            console.error("Token acquisition failed:", error);
            return;
          }
        }

        if (!accessToken) {
          console.error("Access token is empty after token acquisition.");
          return;
        }

        setMsalAccessToken(accessToken);

        try {
          // Fetch the user profile from Microsoft Graph API
          const response = await fetch(
            "https://graph.microsoft.com/v1.0/me?$select=officeLocation,onPremisesExtensionAttributes,companyName,jobTitle",
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            console.error("Failed to fetch user profile:", errorData);
            throw new Error(
              `Failed to fetch user profile: ${response.statusText}`
            );
          }

          const userProfileData = await response.json();

          // Extract user data
          const officeLocation = userProfileData.officeLocation ?? "Unknown";
          const companyName = userProfileData.companyName ?? "Unknown";
          const jobTitle = userProfileData.jobTitle ?? "Unknown";
          const extensionAttributes =
            userProfileData.onPremisesExtensionAttributes;
          const businessUnit =
            extensionAttributes?.extensionAttribute8 ?? "Unknown";

          // Set user profile
          const profile: UserProfile = {
            userId,
            userLocation: officeLocation,
            userLanguage,
            userBusinessUnit: businessUnit,
            userCompany: companyName,
            userJobTitle: jobTitle,
          };

          setUserProfile(profile);
        } catch (fetchError) {
          console.error("Error fetching user profile:", fetchError);
        }
      };

      fetchUserProfile();
    }
  }, [accounts, instance]);

  return userProfile;
}
