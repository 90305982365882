// src/components/RedirectHandler.tsx
import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

const RedirectHandler: React.FC = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then((response) => {
        if (response) {
          console.log("Réponse de la redirection MSAL :", response);
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors du traitement de la redirection MSAL :",
          error
        );
      });
  }, [instance]);

  return null;
};

export default RedirectHandler;
