import { PropsWithChildren } from "react";
import { Box, Typography } from "@mui/material";

import COLORS from "../../utils/styles/colors";

const grey200 = COLORS.BASE.GREY[200];
const grey600 = COLORS.BASE.GREY[600];

const tagSx = {
  backgroundColor: grey200,
  borderRadius: "40px",
  padding: "0 8px",
};

const tagTypoSx = {
  color: grey600,
  fontSize: "13px",
  fontWeight: "700",
};

export interface AssistantTagsProps extends PropsWithChildren {
  readonly tags: string[];
}

export function AssistantTags({ tags }: AssistantTagsProps): JSX.Element {
  // do not show more than 3 tags
  function limitTagsLength(tags: string[]): string[] {
    if (tags.length > 3) return tags.slice(0, 3);
    else return tags;
  }

  return (
    <div className="flex flex-wrap gap-2">
      {!!tags.length &&
        limitTagsLength(tags).map((tag: string, index: number) => (
          <Box key={index} sx={tagSx}>
            <Typography sx={tagTypoSx}>{tag}</Typography>
          </Box>
        ))}
    </div>
  );
}
