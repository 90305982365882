// TODO: get the config from the BFF with GetDefaultParameters.ts and remove mockConfig.ts
export const mockConfig = {
  default: {
    llm_name: "main_llm",
    temperature: 0.2,
    max_output_tokens: 1000,
    number_of_hist_exchanges: 10,
    system_prompt:
      "You are an AI assistant that helps Edenred employees find information, your name is EdenChat.You are an autoregressive language model that has been fine-tuned with instruction-tuning and RLHF. You carefully provide accurate, factual, thoughtful, nuanced answers, and are brilliant at reasoning. If you think there might not be a correct answer, you say so. You give concise answer in few sentences unless being told otherwise.",
    user_prompt: "",
  },
  settings_parameters: {
    temperature: {
      min: 0,
      max: 1,
      step: 0.1,
    },
    max_output_tokens: {
      min: 100,
      medium: 800,
      max: 4000,
    },
  },
  models: [
    {
      llm_name: "main_llm",
      label: "GPT 4o mini",
    },
    {
      llm_name: "secondary_llm",
      label: "GPT 4o",
    },
  ],
  external_urls: {
    quick_start:
      "https://edenred.sharepoint.com/sites/GlobalDataAI-EdenChat/Documents partages/6.0 EdenChat/../../../../:b:/r/sites/AI/Shared%20Documents/EdenChatProd/QuickStart_EdenChat.pdf?csf=1&web=1&e=vcksCG",
    privacy_policy:
      "https://edenred.sharepoint.com/sites/GlobalDataAI-EdenChat/Documents partages/6.0 EdenChat/../../../../:b:/r/sites/AI/Shared%20Documents/EdenChatProd/Privacy_Notice_EdenChat.pdf?csf=1&web=1&e=ySNZhl",
    terms_of_use:
      "https://edenred.sharepoint.com/sites/GlobalDataAI-EdenChat/Documents partages/6.0 EdenChat/../../../../:b:/r/sites/AI/Shared%20Documents/EdenChatProd/TermsofUse_EdenChat.pdf?csf=1&web=1&e=NaMnGZ",
    feedback:
      "https://forms.office.com/Pages/ResponsePage.aspx?id=D54dTCdcKEKjWt57QIP_e57izPzX7wJJgPLRn1syr-VUQ0w4NVFITFdGQ1ZHRlM5MFpMREs1U0RSNC4u",
    help: "https://edenred.sharepoint.com/sites/GlobalDataAI-EdenChat/Documents partages/6.0 EdenChat/../../../../:b:/r/sites/AI/Shared%20Documents/EdenChatProd/Help_EdenChat.pdf?csf=1&web=1&e=NAfncG",
  },
  details: {
    selection:
      "By choosing gpt 4o mini you'll get faster response and reduce the carbon impact of using AI. With gpt 4o you'll get more accurate response.",
    temperature:
      "Controls randomness or 'creativity'. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses.",
    response_length:
      "Set a limit on the number of tokens per model response : Small : max length = 100 tokens = approximatively 75 words,  Medium : max length = 800 tokens = approximatively 600 words, Large : max length = 4000 tokens = approximatively 3000 words",
    system_prompt:
      "Give the model instructions about how it should behave and any context it should reference when generating a response. You can describe the assistant’s personality, tell it what it should and shouldn’t answer, and tell it how to format responses. There’s no token limit for this section, but it will be included with every API call, so it counts against the overall token limit.",
  },
};
