import { axiosClient } from ".";
import { defaultParameters, Parameters } from "../utils/types/parameters";

export async function createNewConversation(
  question: string,
  userToken: string,
  parameters: Parameters,
  ragId: string
): Promise<{ answer: string; conversationId: string }> {
  const data = {
    question: question,
    rag_id: ragId,
  };

  let parametersBFF = {};

  if (parameters.llm !== defaultParameters.llm) {
    parametersBFF = { ...parametersBFF, llm_name: parameters.llm?.llm_name };
  }

  if (parameters.temperature !== defaultParameters.temperature) {
    parametersBFF = { ...parametersBFF, temperature: parameters.temperature };
  }

  if (parameters.systemPrompt !== defaultParameters.systemPrompt) {
    parametersBFF = {
      ...parametersBFF,
      system_prompt: parameters.systemPrompt,
    };
  }

  if (parameters.maxOutputToken !== defaultParameters.maxOutputToken) {
    parametersBFF = {
      ...parametersBFF,
      max_output_token: parameters.maxOutputToken,
    };
  }

  const body =
    Object.keys(parametersBFF).length === 0
      ? data
      : { ...data, parameters: parametersBFF };

  return axiosClient
    .post("/Edenchat/CreateNewConversation", body)
    .then((response: any) => {
      if (response.status !== 200) {
        throw new Error("BFF response.status was not 200");
      }
      return {
        answer: String(response.data.answer),
        conversationId: String(response.data.conversation_id),
      };
    })
    .catch((error: any) => {
      console.error(error);
      return {
        answer: "",
        conversationId: "",
      };
    });
}
