import { Menu, MenuItem, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui/";
import { ConfirmDeleteDialog } from "./ConfirmDeleteDialog";
import { RenameDialog } from "./RenameDialog";

const grey100 = COLORS.BASE.GREY[100];
const grey600 = COLORS.BASE.GREY[600];

const menuSx = {
  "& .MuiPaper-root": {
    borderRadius: "12px",
    padding: "12px",
    width: "245px",
  },
  "& .MuiList-root": { padding: "0px" },
  "& .MuiMenuItem-root": {
    padding: "12px",
    borderRadius: "12px",
    ":hover": { backgroundColor: grey100 },
  },
};

const textSx = {
  fontSize: "16px",
  lineHeight: "24px",
};

export interface HistorySettingsMenuProps extends PropsWithChildren {
  readonly anchorEl: HTMLElement | null;
  readonly anchorElConvId: string;
  readonly oldTitle: string;
  readonly open: boolean;
  readonly handleClose: () => void;
  readonly handleDeleteConversation: (_convId: string) => Promise<void>;
  readonly handleRenameConversation: (
    _convId: string,
    title: string
  ) => Promise<void>;
}
export function HistorySettingsMenu({
  anchorEl,
  anchorElConvId,
  oldTitle,
  open,
  handleClose,
  handleDeleteConversation,
  handleRenameConversation,
}: HistorySettingsMenuProps): JSX.Element {
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  const [openRename, setOpenRename] = useState<boolean>(false);
  const handleOpenRename = () => setOpenRename(true);
  const handleCloseRename = () => setOpenRename(false);

  async function handleDelete() {
    handleDeleteConversation(anchorElConvId);
    handleCloseConfirmation();
    handleClose();
  }

  function handleClickRename() {
    handleOpenRename();
  }

  async function applyRename(_newTitle: string) {
    handleRenameConversation(anchorElConvId, _newTitle);
    handleCloseRename();
    handleClose();
  }

  const menuItems = [
    {
      label: "Rename",
      iconPath: "edit-base-grey-dark.svg",
      handleClick: handleClickRename,
    },
    {
      label: "Delete",
      iconPath: "trash-red.svg",
      handleClick: handleOpenConfirmation,
    },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      onClose={handleClose}
      open={open}
      sx={menuSx}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className="flex flex-col gap-2">
        {menuItems.map((menuItem) => {
          return (
            <MenuItem
              className="flex gap-3"
              key={menuItem.label}
              onClick={menuItem.handleClick}
            >
              <CustomIcon filename={menuItem.iconPath} />
              <Typography
                sx={{
                  ...textSx,
                  color: menuItem.label === "Delete" ? "red" : grey600,
                }}
              >
                {menuItem.label}
              </Typography>
            </MenuItem>
          );
        })}
      </div>

      <RenameDialog
        handleCloseRename={handleCloseRename}
        applyRename={applyRename}
        openRename={openRename}
        oldTitle={oldTitle}
      />
      <ConfirmDeleteDialog
        handleCloseConfirmation={handleCloseConfirmation}
        handleDelete={handleDelete}
        openConfirmation={openConfirmation}
      />
    </Menu>
  );
}
