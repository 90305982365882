// LinkField.tsx

import { Link } from "@mui/material";
import { PropsWithChildren } from "react";

import COLORS from "../../utils/styles/colors";

const defaultBlue = COLORS.SYSTEM.INFO.DEFAULT;

const linkSx = {
  fontWeight: "700",
  color: defaultBlue,
  ":hover": { textDecoration: "underline" },
};

export interface LinkFieldProps extends PropsWithChildren {
  readonly href: string;
  readonly label: string;
  readonly onClick?: () => void;
}

export function LinkField({
  href,
  label,
  onClick,
}: LinkFieldProps): JSX.Element {
  return (
    <Link
      sx={linkSx}
      href={href}
      underline="none"
      target="_blank"
      onClick={onClick}
    >
      {label}
    </Link>
  );
}
