import { mockConfig } from "../mock-data/mockConfig";

export type ModelsType = { label: string; llm_name: string };

export const models: ModelsType[] = mockConfig.models;

// Type guard to check if the key is equal to the `llm_name` property of any element in models
export function isValidKey(key: string) {
  return models.some((model: ModelsType) => model.llm_name === key);
}
