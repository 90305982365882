import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Ubuntu",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "20px",
          fontStyle: "normal",
        },
      },
    },
    MuiButton: { styleOverrides: { root: { minWidth: 0 } } },
  },
});
