import { useMemo } from "react";
import { Message } from "../../utils/types/message";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { oneDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const MessageItem = ({ messageItem }: { messageItem: Message }) => {
  const markdownComponents = useMemo(
    () => ({
      code({ node, inline, className, children, ...props }: any) {
        const match = /language-(\w+)/.exec(className || "");
        const handleCopyCode = () => {
          navigator.clipboard.writeText(String(children).replace(/\n$/, ""));
          toast("Code copied to clipboard!", {
            type: "success",
            autoClose: 1500,
          });
        };

        return !inline && match ? (
          <div style={{ position: "relative" }}>
            <SyntaxHighlighter
              style={oneDark}
              PreTag="div"
              language={match[1]}
              {...props}
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>

            <IconButton
              onClick={handleCopyCode}
              style={{ position: "absolute", top: 0, right: 0 }}
              size="small"
            >
              <Tooltip title="Copy code">
                <ContentCopyIcon style={{ color: "white" }} fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
    }),
    []
  );
  return (
    <Markdown
      skipHtml
      className="markdown prose max-w-full"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={markdownComponents}
    >
      {messageItem.content}
    </Markdown>
  );
};

export default MessageItem;
