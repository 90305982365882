// src/axiosClient.ts

import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import { getAccessToken } from "../auth/authService";
import { apiTokenRequest, loginRequest } from "../auth/authConfig";
import msalInstance from "../auth/msalInstance";
import { toast } from "react-toastify";
import { getMsalAccessToken } from "../utils/sessionStorageUtils";

export const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BFF_URL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the access token in the headers
axiosClient.interceptors.request.use(
  async (config) => {
    try {
      // Acquire token with the correct scopes for the backend API
      const token = await getAccessToken(apiTokenRequest.scopes);
      if (token && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    } catch (error: any) {
      console.error("Error obtaining access token:", error.message);
      // Optional: handle the error if needed
    }
    return config;
  },
  () => {
    return Promise.reject(new Error("Error config."));
  }
);

const refreshPage = () => window.location.reload();

// Add a response interceptor to handle errors globally
axiosClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401 || status === 403) {
        // Unauthorized or Forbidden
        toast.error("Your session has expired. Please log in again.", {
          onClick: () => refreshPage(),
          onClose: () => refreshPage(),
          autoClose: false,
        });
        // Redirect to login
        msalInstance.loginRedirect(loginRequest).catch((err: any) => {
          console.error("Error during login redirect:", err.message);
        });
      } else {
        console.error(
          `Error ${status}: ${error.response.statusText || "Unknown Error"}`
        );
        toast.error("An unknown error has occurred. Please reload the page.", {
          onClick: () => refreshPage(),
          onClose: () => refreshPage(),
          autoClose: false,
        });
      }
    } else if (error.request) {
      console.error("No response received from the server.");
    } else {
      console.error("Error configuring the request:", error.message);
    }

    return Promise.reject(error);
  }
);

// Add MSAL token into the header of the request is GetAnswer
axiosClient.interceptors.request.use(
  (config) => {
    if (
      ["/Edenchat/CreateNewConversation", "/Edenchat/GetAnswer"].includes(
        config.url ?? ""
      )
    ) {
      const msalToken = getMsalAccessToken();
      if (msalToken?.length) {
        config.headers["X-MSAL-Authorization"] = msalToken;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
