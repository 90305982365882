import { PropsWithChildren, useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import TagManager from "react-gtm-module";
import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";
import { Parameters } from "../../utils/types/parameters";
import { AssistantTags } from "./AssistantTags";
import { hasSharepointAccess } from "../../api/SharepointApi";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];
const grey400 = COLORS.BASE.GREY[400];

const cardSx = {
  width: "30%",
  minWidth: "300px",
  height: "300px",
  padding: "20px",
  borderRadius: "16px",
  border: `1px solid ${grey200}`,
  ":hover": { backgroundColor: grey100, cursor: "pointer" },
};

const iconSx = {
  height: "46px",
  width: "46px",
  borderRadius: "16px",
  background:
    "linear-gradient(76deg, #F72717 0%, #FF007D 25%, #711CFF 50%, #006CFA 75%, #05E4C5 100%)",
};

const titleSx = {
  color: greyDark,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",
  maxHeight: "25px",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const questionSx = {
  maxHeight: "40px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
};

const knowledgeSx = {
  fontSize: "13px",
  fontWeight: "700",
};

const urlSx = {
  color: grey400,
  fontSize: "11px",
  lineHeight: "16px",
  wordBreak: "break-word",
};

export interface SmartAssistantTemplateProps extends PropsWithChildren {
  clickAssistant: (
    title: string,
    question: string,
    parameters: Parameters,
    tags: string[],
    url: string,
    ragId: string
  ) => void;
  parameters: Parameters;
  question: string;
  tags: string[];
  title: string;
  url: string;
  ragId: string;
  knowledgeUrl?: string | null;
}

export function SmartAssistantTemplate({
  clickAssistant,
  parameters,
  question,
  tags,
  title,
  url,
  ragId,
  knowledgeUrl,
}: Readonly<SmartAssistantTemplateProps>): JSX.Element {
  const { accounts } = useMsal();
  const userId = accounts[0]?.homeAccountId || "";
  const userLanguage = navigator.language || "en";
  const userLocation = "Unknown"; // Replace with actual location if available
  const [userHasAccess, setUserHasAccess] = useState(false);

  useEffect(() => {
    hasSharepointAccess(url).then((res) => setUserHasAccess(res));
  }, [url]);

  const handleKnowledgeUrlClick = (e) => {
    if (userHasAccess) {
      e.stopPropagation();
      e.preventDefault();
      window.open(knowledgeUrl ?? url, "_blank");
    }
  };

  const handleClickCard = () => {
    // Push the 'assistant' event to dataLayer
    const dataLayerArgs = {
      dataLayer: {
        event: "assistant",
        website: "EdenChat", // Replace with your website name if different
        env_work: process.env.NODE_ENV ?? "development",
        assistant_name: title,
        user_has_access: userHasAccess,
        user_data: {
          user_id: userId,
          user_location: userLocation,
          user_language: userLanguage,
        },
      },
    };
    TagManager.dataLayer(dataLayerArgs);

    // Proceed with the existing click handler
    clickAssistant(title, question, parameters, tags, url, ragId);
  };

  return (
    <Box
      className="flex flex-col justify-between gap-4"
      onClick={handleClickCard}
      sx={{
        ...cardSx,
        opacity: userHasAccess ? 1 : 0.35,
      }}
    >
      <Box className="flex flex-col gap-4">
        <div className="flex justify-start">
          <Box className="flex items-center justify-center" sx={iconSx}>
            <CustomIcon filename="code-2.svg" white />
          </Box>
        </div>

        <div>
          <Typography sx={titleSx}>{title}</Typography>
          <Typography sx={questionSx}>{question}</Typography>
        </div>
      </Box>

      <div className="flex flex-col items-start gap-4">
        <div className="flex flex-col items-start gap-2">
          <div
            onClick={handleKnowledgeUrlClick}
            className="flex items-center gap-2 mb-6 border px-4 py-2 rounded-[100px] bg-gray-100 hover:bg-gray-300 cursor-pointer"
          >
            <CustomIcon filename="layer-base-grey-dark.svg" />
            <Typography sx={knowledgeSx}>Knowledge Base</Typography>
          </div>
          <AssistantTags tags={tags} />
        </div>
      </div>
    </Box>
  );
}
