import { Button, Dialog, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey600 = COLORS.BASE.GREY[600];
const grey700 = COLORS.BASE.GREY[700];

const titleSx = {
  color: greyDark,
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "30px",
};

const descriptionSx = {
  color: grey700,
  fontSize: "16px",
  lineHeight: "24px",
};

const deleteButtonSx = {
  textTransform: "none",
  padding: "12px 20px",
  borderRadius: "12px",
  backgroundColor: greyDark,
  color: "white",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { backgroundColor: grey600 },
};
const cancelButtonSx = {
  textTransform: "none",
  padding: "12px 20px",
  borderRadius: "12px",
  color: greyDark,
  border: `2px solid ${greyDark}`,
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  ":hover": { borderColor: grey600, color: grey600 },
};

export interface ConfirmDeleteDialogProps extends PropsWithChildren {
  readonly openConfirmation: boolean;
  readonly handleCloseConfirmation: () => void;
  readonly handleDelete: () => void;
}

export function ConfirmDeleteDialog({
  openConfirmation,
  handleCloseConfirmation,
  handleDelete,
}: ConfirmDeleteDialogProps): JSX.Element {
  return (
    <Dialog
      open={openConfirmation}
      onClose={handleCloseConfirmation}
      sx={{ ".MuiPaper-root": { width: "500px", borderRadius: "16px" } }}
    >
      <div className="flex flex-col ">
        <div className="flex justify-between px-4 pt-4">
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCloseConfirmation}
          >
            <CustomIcon filename="arrow-left-base-grey-dark.svg" />
          </Button>
          <Button
            sx={{
              borderRadius: "8px",
              ":hover": { backgroundColor: grey100 },
            }}
            onClick={handleCloseConfirmation}
          >
            <CustomIcon filename="close-base-grey-dark.svg" />
          </Button>
        </div>

        <div className="flex flex-col gap-6 px-[40px] pb-[40px]">
          <div className="flex flex-col items-center justify-center">
            <Typography sx={titleSx}>Delete conversation</Typography>
            <Typography sx={descriptionSx}>
              Are you sure to delete this conversation ?
            </Typography>
          </div>

          <div className="flex flex-col items-center justify-center gap-3 px-[90px]">
            <Button
              className="flex gap-2"
              sx={deleteButtonSx}
              fullWidth
              onClick={handleDelete}
            >
              <CustomIcon filename="trash.svg" white />
              Delete conversation
            </Button>
            <Button
              className="flex gap-2"
              sx={cancelButtonSx}
              fullWidth
              onClick={handleCloseConfirmation}
            >
              <CustomIcon filename="close-circle-base-grey-dark.svg" />
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
