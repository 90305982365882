import { AssistantFromBFF } from "../utils/types/assistant";
import { axiosClient } from "./";

export async function getSmartAssistants(
  userToken: string
): Promise<AssistantFromBFF[]> {
  return new Promise((resolve) => {
    axiosClient
      .post("/Edenchat/GetAllSmartAssistants", {})
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("BFF response.status was not 200");
        }
        const smartAssistantsFromBff: AssistantFromBFF[] = response.data;
        resolve(smartAssistantsFromBff);
      })
      .catch((error) => console.error(error));
  });
}
