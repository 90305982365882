import { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { Box, Typography } from "@mui/material";

import COLORS from "../../utils/styles/colors";
import { CustomIcon } from "../base-ui";

const greyDark = COLORS.BASE.GREY.DARK;
const grey100 = COLORS.BASE.GREY[100];
const grey200 = COLORS.BASE.GREY[200];

const cardSx = {
  height: "170px",
  width: "300px",

  padding: "20px",
  borderRadius: "16px",
  border: `1px solid ${grey200}`,
  ":hover": { backgroundColor: grey100, cursor: "pointer" },
};
const iconSx = {
  height: "46px",
  width: "46px",
  borderRadius: "16px",
  padding: "12px",
  backgroundImage:
    "linear-gradient(76deg, #F72717 0%, #FF007D 25%, #711CFF 50%, #006CFA 75%, #05E4C5 100%)",
};
const labelSx = {
  color: greyDark,
  fontSize: "16px",
  fontWeight: "700",
  lineHeight: "24px",

  maxHeight: "25px",
  textWrap: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const detailSx = {
  maxHeight: "40px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
};

export interface SuggestedPromptCardProps extends PropsWithChildren {
  readonly label: string;
  readonly detail: string;
  readonly setText: Dispatch<SetStateAction<string>>;
}

export function SuggestedPromptCard({
  label,
  detail,
  setText,
}: SuggestedPromptCardProps): JSX.Element {
  function handleClickCard(): void {
    setText(detail);
  }
  return (
    <Box className="flex flex-col gap-4" onClick={handleClickCard} sx={cardSx}>
      <div className="flex justify-start">
        <Box className="flex items-center justify-center" sx={iconSx}>
          <CustomIcon filename="code-2.svg" white />
        </Box>
      </div>

      <div>
        <Typography sx={labelSx}>{label}</Typography>
        <Typography sx={detailSx}>{detail}</Typography>
      </div>
    </Box>
  );
}
