const COLORS = {
  SYSTEM: { INFO: { DEFAULT: "#006CFA", BACKGROUND: "#E5F0FE" } },
  BASE: {
    GREY: {
      BACKGROUND: "#F8FAFC",
      DARK: "#0F172A",
      100: "#F1F5F9",
      200: "#E2E8F0",
      300: "#CBD5E1",
      400: "#94A3B8",
      600: "#475569",
      700: "#334155",
    },
    BLACK: { 40: "#14142B66" },
    WHITE: { 40: "#FFFFFF66" },
  },
  NAV_SHADOW: "#D4D7E099",
  LOGOUT_SHADOW: "#101A771F",
  MODELMENU_SHADOW: "#D4D7E066",
  MESSAGE: {
    ANSWER_TITLE: "#686868",
    QUESTION_FONT: "#0D1C8C",
    QUESTION_BACKGROUND: "#E8EAFD",
    ANSWER_SHADOW: "#101A7714",
  },
  BLACK_WHITE: { BLACK: "#0E0E17", WHITE: "#FFFFFF" },
};

export default COLORS;
